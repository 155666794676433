<template>
  <div class="common-layout">   
      <div class="add-wrap">
        <addcon :addData="addData" @addinfo="addinfo" ></addcon>
      </div>
   </div>  
</template>
<script>
import {mapState} from 'vuex'
import addcon from '@/components/addcon.vue'
export default {
  name: 'Add',
  components:{
    // list,
    // search,
    addcon,
    // editcon
  },
  computed:{
    ...mapState(['skt'])
  },
  watch:{
    'skt.saveMsg.1902':{
      handler:function(nval){
        this.menuData = nval
      }
    },    
    'skt.saveMsg.1903':{
      handler:function(nval){
        this.searchData = nval
      }
    },    
    'skt.saveMsg.1904':{
      handler:function(nval){
        this.headData = nval
      }
    },
    'skt.saveMsg.1905':{
      handler:function(nval){
        this.resData = nval
      }
    },
    'skt.saveMsg.1906':{
      handler:function(nval){
        this.pageData = nval
      }
    },
    'skt.saveMsg.1918':{
      handler:function(nval){
        this.addData = nval
      }
    },
    'skt.saveMsg.1908':{
      handler:function(nval){
        this.buttondata = nval
      }
    },
    'skt.saveMsg.6540':{
      handler:function(nval){
        this.editData = nval
      }
    },
    'skt.saveMsg.1907':{
      handler:function(nval){
        this.increaseddata = nval
      }
    },
     'skt.saveMsg.1909':{
      handler:function(nval){
        this.topinfo = nval
      }
    }
  },
  data(){
    return {
      title:"编辑",
      topinfo:[],
      increaseddata:[],
      dialogVisible: false,
      menuActive:'1-1',
      searchData:[],
      menuData:[],
      resData:[],
      headData:[],
      pageData:{},
      menutype:1,
      addData:[],
      editData:[],
      oprtype:0,
      buttondata:{}
    }
  },
  methods:{
      goto(bid,type,menuActive){
        this.menutype = type
        let datas = {
          etid:bid,
          params:{}
        }
        this.$store.dispatch("sendMessage",datas)  
        this.menuActive=menuActive
      },
      subsdata(datas){
        this.$store.dispatch("sendMessage",datas)
      },
      addinfo(datas){        
        // this.goto("1915","1","1-3")      
        this.$store.dispatch("sendMessage",datas)
      },
      pagechange(datas){
        this.$store.dispatch("sendMessage",datas)
      },
      saveinfo(datas){        
       this.clsDialog()
       this.$store.dispatch("sendMessage",datas)
      },
      editinfo(obj){
        this.oprtype = 0
        this.title = "编辑"
        let datas = {
          etid:obj.etid,
          params:{sid:obj.sid}
        }
        this.$store.dispatch("sendMessage",datas)  
        this.dialogVisible = true
      },
      clsDialog(){
        this.dialogVisible = false
      },
      delinfo(obj){
         this.$confirm('你确定本操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           let datas = {
            etid:obj.etid,
            params:{sid:obj.sid}
          }
          this.$store.dispatch("sendMessage",datas)
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消本操作!'
          });          
        });
      },
      increased(title){
        this.oprtype = 1
        this.editData = this.increaseddata
        this.title = title
        this.dialogVisible = true
      }
  }
}
</script>

<style>
.common-layout{
  padding: 0;
  margin: 0;
}
.el-header{
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  padding: 0 20px;
  text-align: left;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px);
  text-align: left;
  box-sizing: content-box;
}

.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px); 
}
.el-submenu__title,.el-menu-item{
  font-size: 12px;
}
.el-dialog__wrapper{
  text-align: left;
}
.increased{
  padding-bottom: 20px;
  text-align: left;
  display: flex;
}
.topinfo-box>div{
padding-bottom: 10px;
}
</style>
