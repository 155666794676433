<template>
  <div class="search">
  <el-form :model="formdata"  ref="formdata"  class="demo-form-inline" label-width="120px" :rules="rules" >
      <el-form-item label="选择模块" >
          <el-select v-model="formdata.xzmk" placeholder="选择模块"  >
            <el-option :label="sd" :value="sd" v-for="(sd,sidx) in selectdata[0].modulename.split(',')" :key="sidx"  :disabled="isxdkmk" ></el-option>
          </el-select>
         </el-form-item>   
         <el-form-item label="选择类型"  >
          <el-select v-model="formdata.xzlx"  >
            <el-option :label="td" :value="tidx" v-for="(td,tidx) in typedata" :key="tidx"  :disabled="isxdkmk" ></el-option>
          </el-select>
         </el-form-item>   
         <el-form-item :label="isxdkmk?'课程标题':'标题'" prop="bt"  >
            <el-input v-model="formdata.bt" placeholder="请输入标题" ></el-input>
         </el-form-item> 
         <h1 v-if="formdata.xzlx==2 || formdata.xzlx==0">学习区</h1>
         <div class="form-wrap" v-if="formdata.xzlx==2 || formdata.xzlx==0">
             <el-form-item label="文字说明" prop="wzms">
           <el-input
          type="textarea"
          :rows="2"
          placeholder="请输文字说明"
          v-model="formdata.wzms">
        </el-input>
         </el-form-item> 
          <el-form-item label="添加图片组" prop="filelist"  >
         <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-preview="handlePreview"
                :on-remove="handleFileRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="6"               
                :on-error="error"
                :on-exceed="handleExceed"
                :file-list="filelst"
                list-type="picture"
                :on-success="handleFileSuccess"
                >
                <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
         </el-form-item>  
         <el-form-item  label="添加视频URL"> 
              <el-button @click="addURL">+添加</el-button>
            </el-form-item>       
         <el-form-item label="视频URL"  v-for="(v,vidx) in formdata.vdolist" :key="vidx" >
          <div class="flex">
            <el-input v-model="v.videolink0" placeholder="请输入视频URL" ></el-input>
            <el-button @click="delurl(vidx)" type="danger" icon="el-icon-delete" circle></el-button></div>
         </el-form-item>
          <el-form-item label="添加视频" v-if="!formdata.videolink0" prop="videoarr0">
         <el-upload class="avatar-uploader el-upload--text" 
            :action="uploadUrl" 
            :show-file-list="false" 
            :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo" 
            :on-error="error"
            @click="cvdo(0)"
            :on-progress="uploadVideoProcess">   
            <template v-if="JSON.stringify(formdata.videoarr0) != '[]' && videoFlag == false">
                <video  :src="formdata.videoarr0[1]" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
            </template> 
            <template v-else-if="JSON.stringify(formdata.videoarr0) == '[]' && videoFlag == false">
                <i  class="el-icon-plus avatar-uploader-icon"></i>
            </template>  
              <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
            </el-upload>
            <div class="red" @click="dellvdo(0,-1)"  v-if="formdata.videoarr0.lenght !=0">删除视频</div>   
         </el-form-item> 
         <el-form-item >
           <span class="red">*</span>视频或视频URL地址只需上传其中之一
         </el-form-item>
         <el-form-item  label="添加语音"   prop="audioarr2">      
                <el-upload
                      id="audioUpload"
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="true"
                        :multiple="false"
                        :on-remove="handleAudioRemove2"
                        :limit="1"
                        :auto-upload="true"
                        :on-change="handleChangeAudio"
                        :on-success="handleAudioSuccess2"
                        :on-error="error"
                        :file-list="filelst1"
                        :before-upload="beforeAudioUpload">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    <div slot="tip" class="el-upload__tip" style="margin-bottom: 10px">
                        只能上传mp3文件，且不超过2M，播放长度不超过5分钟
                    </div>
          </el-upload>
              <audio :src="formdata.audioarr2.url" controls="controls" v-if="formdata.audioarr2.url">
              您的浏览器不支持 audio 标签
              </audio>  
              <div class="red" @click="dellAudio(2,-1)"  v-if="formdata.audioarr2.url">删除语音</div>             
         </el-form-item> 
         </div>        
         <div class="form-wrap" v-if="formdata.xzlx==1 || formdata.xzlx==2">
          <el-form-item  label="习题集"> 
              <el-button @click="addItem(0)">+单选</el-button>
              <el-button  @click="addItem(1)">+多选</el-button>
            </el-form-item>         
         <div class="item border-radius" v-for="(item,index) in formdata.xtj" :key="index">
          <h1><div>问题区</div><el-button @click="delxtj(index)" type="danger" icon="el-icon-delete" circle></el-button></h1>           
              <template v-if="item.wtindex == 0">
              <el-form-item label="单选问题" prop="wtms"  >
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入单选标题"
                    v-model="item.wtms">
                  </el-input>
              </el-form-item>              
              <el-form-item :label="`单选内容：${i}`" prop="mvalue"  v-for="(mvalue,i) in item.wtda" :key="i" >
                <el-input v-model="item.wtda[i]" placeholder="请输入单选内容" ></el-input>
              </el-form-item>                   
               <el-form-item label="正确答案" prop="wtjg"  >
                <el-input v-model="item.wtjg" type="number"  placeholder="*请输入单选正确答案数字" ></el-input>
              </el-form-item> 
              </template>
              <template v-else>
                  <el-form-item label="多选问题" prop="wtms"  >
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入多选标题"
                    v-model="item.wtms">
                  </el-input>
                  </el-form-item>              
                  <el-form-item :label="`多选内容：${i}`" prop="mvalue"  v-for="(mvalue,i) in item.wtda" :key="i" >
                    <el-input v-model="item.wtda[i]" placeholder="请输入单选内容" ></el-input>
                  </el-form-item>                   
                  <el-form-item label="正确答案" prop="wtjg"  >
                    <el-input v-model="item.wtjg"   placeholder="*请输入多选正确答案数字,多个用','隔开" ></el-input>
                  </el-form-item> 
              </template>
               <el-form-item  label=""> 
                <el-button  @click="addda(index)">+选值</el-button>
                <el-button   @click="delda(index)">-选值</el-button>
              </el-form-item>
              <el-form-item  label="添加图片"   >      
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="false"
                         :on-success="(res)=>handleAvatarSuccess(res,index)"
                        :on-error="error"
                         :data-index="index"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="item.wttp" :src="item.wttp" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                        <div class="red" v-if="item.wttp" @click="handleFileRemove0(index)">删除图片</div>
                </el-form-item>
                <el-form-item  label="添加语音" prop="wtyy" >      
                    <el-upload
                          id="audioUpload"
                          class="avatar-uploader"
                            :action="uploadUrl"
                            :show-file-list="true"
                            :multiple="false"
                            :on-remove="handleAudioRemove"
                            :limit="1"
                            :auto-upload="true"
                            :on-change="handleChangeAudio"
                            :on-success="(res)=>handleAudioSuccess(res,index)"
                            :on-error="error"
                            :file-list="filelst2"
                            :before-upload="beforeAudioUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="margin-bottom: 10px">
                            只能上传mp3文件，且不超过2M，播放长度不超过5分钟
                        </div>
                    </el-upload>
                    <audio :src="item.wtyy.url" controls="controls" v-if="item.wtyy.url">
                    您的浏览器不支持 audio 标签
                    </audio>      
                    <div class="red" @click="dellAudio(0,index)"  v-if="item.wtyy.url">删除语音</div>     
                </el-form-item>  
                <h1>解析区</h1>
                  <el-form-item label="答案解析文字"  prop="jxwz">
                   <el-input v-model="item.jxwz" placeholder="请输入答案解析文字" ></el-input>
                 </el-form-item>   
                 <el-form-item  label="添加图片"  prop="jxtp" >      
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="(res)=>handleAvatarSuccess1(res,index)"
                        :on-error="error"
                        :data-index="index"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="item.jxtp" :src="item.jxtp" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="red" v-if="item.jxtp" @click="handleFileRemove1(index)">删除图片</div>
                </el-form-item>
                <el-form-item  label="添加语音" prop="jxyy">      
                <el-upload
                      id="audioUpload"
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="true"
                        :multiple="false"
                        :on-remove="handleAudioRemove1"
                        :limit="1"
                        :auto-upload="true"
                        :on-change="handleChangeAudio"
                        :on-success="(res)=>handleAudioSuccess1(res,index)"
                        :on-error="error"
                        :file-list="filelst3"
                        :before-upload="beforeAudioUpload">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    <div slot="tip" class="el-upload__tip" style="margin-bottom: 10px">
                        只能上传mp3文件，且不超过2M，播放长度不超过5分钟
                    </div>                  
                  </el-upload>
                      <audio :src="item.jxyy.url" controls="controls" v-if="item.jxyy.url">
                      您的浏览器不支持 audio 标签
                      </audio>     
                      <div class="red" @click="dellAudio(1,index)"  v-if="item.jxyy.url">删除语音</div>     
                </el-form-item>
                  <el-form-item label="视频URL"  v-if="!item.jxmov.lenght" prop="jxspdz">
                    <el-input v-model="item.jxspdz" placeholder="请输入视频URL" ></el-input>
                </el-form-item>
                  <el-form-item label="添加视频" v-if="!item.jxspdz" prop="jxmov" >
                <el-upload class="avatar-uploader el-upload--text" 
                    :action="uploadUrl" 
                    :show-file-list="false" 
                    :on-success="(res)=>handleVideoSuccess1(res,index)" :before-upload="beforeUploadVideo" 
                    :on-error="error"
                    :on-progress="uploadVideoProcess">
                     <template v-if="JSON.stringify(item.jxmov) != '[]' && videoFlag1 == false">
                        <video  :src="item.jxmov[1]" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                    </template> 
                    <template v-else-if="JSON.stringify(item.jxmov) == '[]' && videoFlag1 == false">
                        <i  class="el-icon-plus avatar-uploader-icon"></i>
                    </template>   
                      <el-progress v-if="videoFlag1 == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
                    </el-upload>
                    <div class="red" @click="dellvdo(1,index)"  v-if="item.jxmov.lenght !=0">删除视频</div>   
                </el-form-item> 
              </div>
             
           <!-- <el-form-item label="问题描述" prop="wtms"  >
            <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输文字说明"
                      v-model="formdata.wtms">
                    </el-input>
                  </el-form-item>
                <el-form-item  label="添加图片"   >      
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="false"
                         :on-success="handleAvatarSuccess"
                        :on-error="error"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="formdata.wttp" :src="formdata.wttp" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                        <div class="red" v-if="formdata.wttp" @click="handleFileRemove0">删除图片</div>
                </el-form-item>
                <el-form-item  label="添加语音" prop="wtyy" >      
                <el-upload
                      id="audioUpload"
                      class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="true"
                        :multiple="false"
                        :on-remove="handleAudioRemove"
                        :limit="1"
                        :auto-upload="true"
                        :on-change="handleChangeAudio"
                        :on-success="handleAudioSuccess"
                          :on-error="error"
                           :file-list="filelst2"
                        :before-upload="beforeAudioUpload">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    <div slot="tip" class="el-upload__tip" style="margin-bottom: 10px">
                        只能上传mp3文件，且不超过2M，播放长度不超过5分钟
                    </div>
                  
          </el-upload>
              <audio :src="audioList0.url" controls="controls" v-if="audioList0.url">
              您的浏览器不支持 audio 标签
              </audio>      
              <div class="red" @click="dellAudio(0)"  v-if="audioList0.url">删除语音</div>           
             </el-form-item>
                 <el-form-item label="答题类型" prop="wtindex"  >
                  <el-select v-model="formdata.wtindex"  >
                    <el-option :label="type" :value="type" v-for="(type,tyidx) in dttype" :key="tyidx"  ></el-option>
                  </el-select>
                 </el-form-item>
                   <el-form-item label="答题结果" prop="wtda"  >
                   <el-input v-model="formdata.wtda" placeholder="请输入答题结果" ></el-input>
                  <div >
                        多个答案请用,号形式填写
                    </div>
                 </el-form-item>    
         </div>
          <h1 v-if="formdata.xzlx=='学习' || formdata.xzlx=='刷题' ">解析区</h1>
         <div class="form-wrap" v-if="formdata.xzlx=='学习' || formdata.xzlx=='刷题' ">    
              <el-form-item label="答案解析文字"  prop="jxwz">
                   <el-input v-model="formdata.jxwz" placeholder="请输入答案解析文字" ></el-input>
                 </el-form-item>   
                 <el-form-item  label="添加图片"  prop="jxtp" >      
                <el-upload
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        :on-error="error"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="formdata.jxtp" :src="formdata.jxtp" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div class="red" v-if="formdata.jxtp" @click="handleFileRemove1">删除图片</div>
                </el-form-item>
                <el-form-item  label="添加语音" prop="jxyy">      
                <el-upload
                      id="audioUpload"
                        class="avatar-uploader"
                        :action="uploadUrl"
                        :show-file-list="true"
                        :multiple="false"
                        :on-remove="handleAudioRemove1"
                        :limit="1"
                        :auto-upload="true"
                        :on-change="handleChangeAudio"
                        :on-success="handleAudioSuccess1"
                          :on-error="error"
                           :file-list="filelst3"
                        :before-upload="beforeAudioUpload">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    <div slot="tip" class="el-upload__tip" style="margin-bottom: 10px">
                        只能上传mp3文件，且不超过2M，播放长度不超过5分钟
                    </div>                  
          </el-upload>
              <audio :src="audioList1.url" controls="controls" v-if="audioList1.url">
              您的浏览器不支持 audio 标签
              </audio>     
               <div class="red" @click="dellAudio(1)"  v-if="audioList1.url">删除语音</div>     
         </el-form-item>
          <el-form-item label="视频URL"  v-if="!formdata.jxmov.lenght" prop="jxspdz">
            <el-input v-model="formdata.jxspdz" placeholder="请输入视频URL" ></el-input>
         </el-form-item>
          <el-form-item label="添加视频" v-if="!formdata.jxspdz" prop="jxmov" >
         <el-upload class="avatar-uploader el-upload--text" 
            :action="uploadUrl" 
            :show-file-list="false" 
            :on-success="handleVideoSuccess1" :before-upload="beforeUploadVideo" 
            :on-error="error"
            :on-progress="uploadVideoProcess">
              <video v-if="videoForm1.video !='' && videoFlag1 == false" :src="videoForm1.video" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
              <i v-else-if="videoForm1.video =='' && videoFlag1 == false" class="el-icon-plus avatar-uploader-icon"></i>
              <el-progress v-if="videoFlag1 == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
            </el-upload>
             <div class="red" @click="dellvdo(1)"  v-if="videoForm1.video !=''">删除视频</div>   
         </el-form-item> -->
          <el-form-item >
           <span class="red">*</span>视频或视频URL地址只需上传其中之一
         </el-form-item>  
         </div>         
      <el-button type="primary" @click="onSubmit('formdata')">确定</el-button>
      <el-button @click="resetForm('formdata')">重置</el-button>
  </el-form> 
  </div>
</template>

<script>

export default {
  name: 'adddata',
  props: {
    selectdata: {
      type:Array,
      default:()=>[]
    },
    isxdkmk: {
      type:Boolean,
      default:false
    },
    orderid:{
      type:String,
      default:'-1'
    },
    editorderid:{
      type:String,
      default:"-1"
    },
    curxdkmk:{
       type:Object,
      default:()=>{}
    },
    editxdkdata:{
       type:Object,
      default:()=>{}
    },
    iseditxdkkc:{
      type:Boolean,
      default:false
    }
  },
  watch:{
     'editxdkdata':{
      handler:function(nval){
        if(this.iseditxdkkc){
          this.formdata = nval
          if(nval.videoarr0.length){
            this.videoForm0 = nval.videoarr0[0]
            this.videoFlag = false
          }
          if(nval.jxmov.length){
            this.videoForm1 = nval.jxmov[0]
            this.videoFlag1 = false
          }
            if(nval.filelist.length){
            this.filelst = nval.filelist
          }
           if(nval.wtyy.url){
            this.audioList0.url = nval.wtyy.url
          }
          if(nval.jxyy.url){
            this.audioList1.url = nval.jxyy.url
          }
          if(nval.audioarr2.url){
            this.audioList2.url = nval.wtyy.url
          }
          }else{
            this.clearfform()
        }
      },
      immediate:true
    },
    filelst(newval){
     this.formdata.filelist=newval
    },
    'curxdkmk':{
      handler:function(nval){
         this.formdata.xzmk = nval.xzmk
      this.formdata.xzlx = nval.xzlx
      },
      deep:true,
      immediate:true
    }
  },
  data() {
      return {
      tvalue:'',
      formdata: {
        xzmk:undefined,
        xzlx:undefined,
        // wtyy:{url:''},
        jxyy:{},
        audioarr2:{url:''},
        bt:undefined,
        xtj:[],
        wzms:undefined,
        // wttp:'',        
        // jxtp:'',
        videoarr0:[],       
        // jxmov:[],
        vdolist:[{
          videolink0:undefined,
        }],
        videolink0:undefined,
        // jxspdz:undefined,
        // wtms:undefined,
        filelist:[],
        // wtindex:undefined,
        // wtda:undefined,        
        // jxwz:undefined     
      } ,
      typedata:["赏析","刷题","学习"],
      dttype:["单选","多选","填空","判断","语音"],
      rules:{
        bt:[
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
      },
      uploadUrl:this.$store.state.BASE_URL+'file/upload' ,
      videoForm0:{
        video:'',
        movurl:""
      },
      videoForm1:{
        video:'',
        movurl:""
      },
      curindex:-1,
      videoFlag:true,
      videoFlag1:true,
      videoUploadPercent:0,
      filelst:[],
      filelst1:[],
      filelst2:[],
      filelst3:[],
      audioList0:{
        url:''
      },
      audioList1:{
        url:''
      },
      audioList2:{
        url:''
      },
      audioDuration:0
    }
  },
  methods: {
    dellvdo(num,index){
      console.log(index,num)
      switch (num) {
        case 0:
            this.formdata.videoarr0 = []
            this.videoFlag = false
          break;
         case 1:
          this.formdata.xtj[index].jxmov = []
            this.videoFlag1 = false
          break;      
        default:
          break;
      }
    },
    handleChange(e,id){
      this.formdata[id]=e
    },
    addda(index){
				this.formdata.xtj[index].wtda.push(this.tvalue)
			},
			delda(index){
				if(this.formdata.xtj[index].wtda.length>1){
					this.formdata.xtj[index].wtda.splice(this.formdata.xtj[index].wtda.length-1,1)
				}
			},
      addURL(){
        let temp = {
          videolink0:''
        }
        this.formdata.vdolist.push(temp)
      },
    addItem(num){
				var dataTemp = {
        wtindex:num,
        wtms:'',
        wttp:[],
        wtyy:{},
        wtda:[],
        jxwz:'',
        jxtp:[],
        wtjg:'',
        jxyy:{},
        jxspdz:'',
        jxmov:[],
      }
      this.formdata.xtj.push(dataTemp)
      this.formdata.xtj[this.formdata.xtj.length-1].wtda.push(this.tvalue)
      this.formdata.xtj[this.formdata.xtj.length-1].wtda.push(this.tvalue)
    },
    delurl(i){
      this.formdata.vdolist.splice(i,1)
    },
    delxtj(i){
      this.formdata.xtj.splice(i,1)
    },
    onSubmit(formName) {
      if(this.formdata.xzlx == 1){
        console.log(this.formdata.xtj.length)
					if(this.formdata.xtj.length == 0){
            this.poptips("刷题类弄必须有一个问题列表")
						return
					}
          for (let q in this.formdata.xtj) {
						if(this.formdata.xtj[q].wtms == ''){
							this.poptips("请输入问题标题")
							return
						}
						if(this.formdata.xtj[q].wtjg == ''){
							this.poptips("请输入问题正确答案")
							return
						}
						if(this.formdata.xtj[q].wtda.length < Number(this.formdata.xtj[q].wtda)){
							this.poptips("问题正确答案序号不能大于你添加的选择值")
							return
						}
						for (let j in this.formdata.xtj[q].wtda) {
							if( this.formdata.xtj[q].wtda[j]== ''){
								this.poptips("请输入问题选项值")
								return
							}
						}
						if(this.formdata.xtj[q].jxwz == ''){
							this.poptips("请输入解析文字")
							return
						}
					}
        }
       
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let itid = this.orderid=='-1'?'8888':"8891"
          if(this.orderid !="-1"){
            this.formdata['orderid']=this.orderid
            if(this.editorderid !="-1"){
              itid="8899"
              this.formdata['kcorderid']=this.editorderid
            }
          }
          let sdata = {
            etid:itid,
            params:this.formdata
          }      
          this.$emit("saveaddinfo",sdata)
          this.resetForm(formName)
        } else {
          this.$message({
            message:"*号内容为必填内容",
            type: 'warning'
          });
          return false;
        }
      });      
    },
     poptips(msg){
            this.$message({
              message:msg,
              type: 'warning'
            });
        },
    //文件图片
    handleAvatarSuccess(res,index) {
       if(res.code == 0){
         let url = res.url
            this.formdata.xtj[index].wttp = url
       }
    },
    handleAvatarSuccess1(res,index) {
       if(res.code == 0){
         let url = res.url
          this.formdata.xtj[index].jxtp = url
       }
    },
    error(){
      this.$message.error('文件上传失败!');
    },
    beforeAvatarUpload(file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //上传语音
    beforeAudioUpload(file){
      // 文件类型进行判断
        const isAudio = file.type === "audio/mp3" || file.type === "audio/mpeg";
        // 限制上传文件大小 2M
        const isLt2M = file.size / 1024 / 1024 < 2;     
       
        // 获取时长
        this.getTimes(file);
        if (!isAudio) {
            this.$message.error("上传文件只能是Mp3格式!");
        } else {
            if (!isLt2M) {
                this.$message.error("上传文件大小不能超过 2MB!");
            }
        }
        return isAudio && isLt2M
    },
    getTimes(file) {
            var content = file;
            //获取录音时长
            var url = URL.createObjectURL(content);
            //经测试，发现audio也可获取视频的时长
            var audioElement = new Audio(url);
            audioElement.addEventListener("loadedmetadata", () => {
                this.audioDuration = parseInt(audioElement.duration);
                 const isTime300S = this.audioDuration >= 300 ? true : false;  
               if (isTime300S) {
                    this.$message.error("上传文件时长不能超过5分钟!");
                 }                
            });
        },
    handleAudioSuccess(res,index){  
      if(res.code == 0){   
          // this.audioList0.url = res.url    
        this.formdata.xtj[index].wtyy["content"] = res.url
         this.formdata.xtj[index].wtyy["contentduration"] = this.audioDuration
         this.formdata.xtj[index].wtyy["anmitionplay"]=0
       }else{
        this.$message.error('语音上传失败，请重新上传！');
      }
    },
    handleAudioSuccess1(res,index){    
      if(res.code == 0){   
        this.audioList1.url = res.url        
        this.formdata.xtj[index].jxyy["content"] = res.url
        this.formdata.xtj[index].jxyy["contentduration"] = this.audioDuration
        this.formdata.xtj[index].jxyy["anmitionplay"]=0
       }else{
        this.$message.error('语音上传失败，请重新上传！');
      }
    },
     handleAudioSuccess2(res){ 
      if(res.code == 0){   
        // this.audioList2.url = res.url   
         this.formdata.audioarr2["content"] = res.url
        this.formdata.audioarr2["contentduration"] = this.audioDuration
        this.formdata.audioarr2["anmitionplay"]=0
       }else{
        this.$message.error('语音上传失败，请重新上传！');
      }
    },
    handleChangeAudio(file, fileList){
      console.log(file,fileList)
    },
    //视频上传
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024  < 10;
      if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
          this.$message.error('请上传正确的视频格式');
          return false;
      }
      if (!isLt10M) {
          this.$message.error('上传视频大小不能超过10MB哦!');
          return false;
      }
    },
    uploadVideoProcess(file){
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percent.toFixed(0));
    },
    handleVideoSuccess(res) {                   
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if(res.code == 0){
        // this.videoForm0.movurl = res.movurl
        // this.videoForm0.video = res.url;       
        this.formdata["videoarr0"].push(res.movurl)
         this.formdata["videoarr0"].push(res.url)
      }else{
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    handleVideoSuccess1(res,index) {                      
      this.videoFlag1 = false;
      this.videoUploadPercent = 0;
      if(res.code == 0){
          this.videoForm1.movurl = res.movurl
        this.videoForm1.video = res.url;
        this.formdata.xtj[index]["jxmov"].push(res.movurl)
        this.formdata.xtj[index]["jxmov"].push(res.url)
      }else{
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    //上传文件
    handleFileRemove(file, fileList) {
      console.log(file)
      this.filelst = []
      for(let i in fileList){
         this.filelst.push({name:fileList[i].name,url:fileList[i].response.url})
      }
    },
    handleAudioRemove(){
      this.formdata.audioazrr0 = {}
      this.audioList0={url:''}
    },
     handleAudioRemove1() {
      this.formdata.audioazrr1 = {}
      this.audioList1={url:''}
    },
    handleAudioRemove2(){
      this.formdata.audioazrr2 = {}
      this.audioList2={url:''}
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleFileRemove1(){
  this.formdata.jxtp = ''
    },
    handleFileRemove0(index){
      this.formdata.xtj[index].wttp = ''
    },
  
    clearfform(){
      this.videoForm0={
          video:'',
          movurl:""
        }
        this.videoForm1={
          video:'',
          movurl:""
        }
        this.videoUploadPercent=0
        this.filelst=[]
        this.audioList0={
          url:''
        }
        this.audioList1={
          url:''
        }
        this.audioList2={
          url:''
        }
        this.videoFlag = true
        this.videoFlag1 = true
        this.audioDuration=0
        this.formdata.filelist = []
        this.formdata.jxtp=''
        this.formdata.bt=''
        this.formdata.xtj=[]
        this.formdata.videoarr0=[] 
        this.formdata.videolink0=''
        this.formdata.wtindex=''
        this.formdata.audioarr2={url:''}
        this.filelst1=[]
        this.filelst2=[]
        this.filelst3=[]
    },
    dellAudio(num,index){
      switch (num) {
        case 0:
           this.formdata.xtj[index].wtyy={url:''}
           this.audioList0={
            url:''
           }
          this.filelst2 = []
          break;
        case 1:
            this.formdata.xtj[index].jxyy={url:''}
           this.audioList1={
            url:''
           }
          this.filelst3 = []
          break;
        case 2:
            this.formdata.audioarr2={url:''}
           this.audioList2={
            url:''
           }
          this.filelst1 = []
          break;        
        default:
          break;
      }
    },
     resetForm(formName) {
       this.clearfform()
         this.$refs[formName].resetFields();
    },
    handleFileSuccess(res,file){
      if(res.code == 0){
        let obj = {
          url:res.url,
          name:file.name
        }
        this.formdata.filelist.push(obj)
      }else{
          this.$message.error('文件上传失败，请重新上传！');
      }
    }
  },
  onunload(){
    this.$refs["formdata"].resetFields();
  }
}
</script>
<style>
.search{
  text-align: left;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
        
  }
  h1{
    display: flex;
    align-items:center; 
    justify-content: space-between;
  }
  h1 >div{
    margin-right: 10px;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #8c939d;
    width:100px;
    height: 60px;
    padding-top: 20px;
    text-align: center;
  }
  .avatar {
    width: 200px;
    height: 178px;
    display: block;
  }
  .item{
    border-radius: 8px;
    padding: 0 10px;
    margin-bottom: 10px;
    border:1px solid #409EFF;
  }
  .flex{
    display: flex;
  }
</style>
