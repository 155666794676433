<template>
  <div class="search">
  <el-form :model="formInline"  ref="formInline"  class="demo-form-inline" label-width="150px" :rules="rules" >
      <el-form-item :label="sd.showname+':'" :prop="sd.queryid"   v-for="(sd,index) in addData" :key="index" >
          <el-input v-model="formInline[sd.queryid]" :placeholder="sd.hint" v-if="sd.type == 1"></el-input>
          <el-select v-model="formInline[sd.queryid]" :placeholder="sd.hint" v-else-if="sd.type == 2">
            <el-option :label="rlc" :value="rlc" v-for="(rlc,ridx) in sd.inputinfo.split(',')" :key="ridx"  ></el-option>
          </el-select>
          <el-input v-model="formInline[sd.queryid]" :placeholder="sd.hint" type="password" v-else-if="sd.type == 3"></el-input>
          <el-input v-model="formInline[sd.queryid]" :placeholder="sd.hint" type="number" v-else-if="sd.type == 5"></el-input>
          <el-checkbox-group v-model="sd.empty"  v-else-if="sd.type == 4"  @change="handleChange($event,sd.queryid)">
            <el-checkbox :label="slc" name="type" v-for="(slc,sidx) in sd.inputinfo.split(',')" :key="sidx"></el-checkbox>         
          </el-checkbox-group>
          <template v-else-if="sd.type == 8">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :data-qid="sd.queryid"
              :on-error="error"
              :click="cfile(1,sd.queryid)"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </template>
          <template v-else-if="sd.type == 16">
            <el-upload class="avatar-uploader el-upload--text" 
            :click="cfile(2,sd.queryid)"
            :action="uploadUrl" 
            :show-file-list="false" 
            :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo" 
            :on-error="error"
            :on-progress="uploadVideoProcess">
              <video v-if="videoForm.Video !='' && videoFlag == false" :src="videoForm.video" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
              <i v-else-if="videoForm.Video =='' && videoFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
              <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
            </el-upload>
          </template>
          <template v-else-if="sd.type == 32">
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :limit="3"
                :on-error="error"
                :on-exceed="handleExceed"
                :file-list="filelst"
                :on-success="handleFileSuccess"
                :click="cfile(3,sd.queryid)"
                >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
          </template>
      </el-form-item>   
    <el-form-item>
      <el-button type="primary" @click="onSubmit('formInline')">新增</el-button>
      <el-button @click="resetForm('formInline')">重置</el-button>
    </el-form-item>
  </el-form> 
  </div>
</template>

<script>
export default {
  name: 'adddata',
  props: {
    addData: {
      type:Array,
      default:()=>[]
    }
  },
  watch:{
    imageUrl(newval){
       this.formInline[this.picqid]=newval
    },
    'videoForm.Video'(newval){
      this.formInline[this.vdoqid].movurl=this.videoForm.movurl
      this.formInline[this.vdoqid].video=newval
    },
    filelst1(newval){
      this.formInline[this.filqid]=newval
    }
  },
  data() {
      return {
      formInline: {} ,
      imageUrl: '' ,
      rules:{
        e2011:[
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ]
      },
      uploadUrl:this.$store.state.BASE_URL+'file/upload' ,
      videoForm:{
        video:'',
        movurl:""
      },
      videoFlag:true,
      videoUploadPercent:0,
      filelst:[],
      filelst1:[],
      picqid:'',
      vdoqid:'',
      filqid:''
    }
  },
  methods: {
    handleChange(e,id){
      this.formInline[id]=e
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let sdata = {
            etid:this.addData[0].eid,
            params:this.formInline
          }      
          this.$emit("addinfo",sdata)
           this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });      
    },
    //文件上传
    handleAvatarSuccess(res) {
       if(res.code == 0){
         let url = res.url
         this.imageUrl = url
       }
    },
    cfile(num,qid){
      if(num == 1){
        this.picqid = qid
      }else if(num == 2){
        this.vdoqid = qid
      }else if(num == 3){
        this.filqid = qid
      } 
    },
    error(){
      this.$message.error('文件上传失败!');
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //视频上传
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024  < 10;
      if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
          this.$message.error('请上传正确的视频格式');
          return false;
      }
      if (!isLt10M) {
          this.$message.error('上传视频大小不能超过10MB哦!');
          return false;
      }
    },
    uploadVideoProcess(file){
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
    },
    handleVideoSuccess(res) {                           
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if(res.code == 0){
        this.videoForm.movurl = res.movurl
        this.videoForm.video =res.url;
      }else{
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    //上传文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      // console.log("fileList",fileList)
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
     resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleFileSuccess(res){
      if(res.code == 0){
        let url = res.url
        this.filelst1.push(url)
      }else{
          this.$message.error('文件上传失败，请重新上传！');
      }
    }
  },
  onunload(){
    this.$refs["formInline"].resetFields();
  }
}
</script>
<style>
.search{
  text-align: left;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
        
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #8c939d;
    width:80px;
    height: 60px;
    padding-top: 20px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
