import {
  SOCKET_ONOPEN,
  SOCKET_ONCLOSE,
  SOCKET_ONERROR,
  SOCKET_ONMESSAGE,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ERROR
} from '../mutation-types'

const state = {
  socket: {
    isConnected: false,
    message: {},
    reconnectError: false,  
    count:0 
  },
  saveMsg:{
    1901:{canlog:0},
    1902:[],
    1903:[],
    1904:[],
    1905:[],
    1906:[],
    1907:[],
    1908:{},
    1909:[],
    1910:[],
    1911:[],
    1918:[],
    6540:[],
    6669:[],
    1893:[],
    1894:[],
    1895:[],
    1896:[],
    1899:[],
    8891:[],
    8892:[],
    8895:[],
    8896:[],
    8898:[],
    2000:[],
    2010:[],
    8899:[],
    2012:[],
    2014:[],
    2016:[],
    10211:[]
  }
}
const mutations = {
  [SOCKET_ONOPEN](state) {
    state.socket.isConnected = true   
    // console.log("event.currentTarget",event.currentTarget)
  },
  [SOCKET_ONCLOSE](state) {
    // console.error(state, event)
    state.socket.isConnected = false
  },
  [SOCKET_ONERROR](state, event){
    console.error("modules",state, event)
  },
  // default handler called for all methods
  [SOCKET_ONMESSAGE](state, msg) {    
    // console.log("收到消息message",msg)
    state.socket.message = msg.data
    if( state.socket.message != "heartbeat" &&  state.socket.message){     
      let info = JSON.parse(state.socket.message)
      if(state.saveMsg['1901'].canlog == 1){
        console.log("收到消息----",info.etid,info.datas)
      }
      state.saveMsg[info.etid]=info.datas
    }
  },
  // mutations for reconnect methods
  [SOCKET_RECONNECT](state, count) {
    console.info("重新连接",state, count)
  },
  [SOCKET_RECONNECT_ERROR](state) {
    state.socket.reconnectError = true
  }
}

export default {  
  namespaces: true,
  state,
  mutations
}
