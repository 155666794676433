<template>
  <div class="common-layout">
    <el-container>
      <el-header>通用管理平台</el-header>
      <el-container>
        <el-aside width="200px">
           <el-menu
              :default-active="menuActive"
              class="el-menu-vertical-demo">
              <span v-for="(rdata,midx) in menuData" :key="midx">
                  <template v-if="rdata.menulevel==1">
                    <el-menu-item index="2" v-for="(mdata,midx) in rdata.menucon" :key="midx" @click="goto(mdata.bid,mdata.menutype,mdata.menuid)" >
                      <i :class="mdata.icon"></i>
                      <span slot="title">{{mdata.title}}</span>
                    </el-menu-item>                    
                  </template>
                  <template v-if="rdata.menulevel==2">
                    <el-submenu :index="mdata.menuid"  v-for="(mdata,midx) in rdata.menucon" :key="midx">
                      <template slot="title">
                        <i :class="mdata.icon"></i>
                        <span>{{mdata.title}}</span>
                      </template>
                      <el-menu-item :index="smdata.submenuid" @click="goto(smdata.bid,smdata.submenutype,smdata.submenuid)" v-for="(smdata,smidx) in mdata.submenu" :key="smidx"><i :class="smdata.subicon" ></i>{{smdata.subtitle}}</el-menu-item> 
                    </el-submenu>
                  </template>
              </span>
            </el-menu>  
        </el-aside>
        <el-main>
          <router-view></router-view>
         </el-main>
      </el-container>
    </el-container>
  </div>  
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'Main',
  computed:{
    ...mapState(['skt'])
  },
  watch:{
    'skt.saveMsg.1902':{
      handler:function(nval){
        this.menuData = nval
      }
    },  
    'skt.saveMsg.6540':{
      handler:function(nval){
        this.editData = nval
      }
    }
  },
  data(){
    return {
      title:"编辑",
      dialogVisible: false,
      menuActive:'1-1',
      menuData:[],
      menutype:1,
      editData:[],
      oprtype:0,   
    }
  },
  methods:{
      goto(bid,type,menuActive){
        this.menutype = type
        let datas = {
          etid:bid,
          params:{}
        }
        this.$store.dispatch("sendMessage",datas)  
        this.menuActive=menuActive
        if(type == 4){
          this.$router.push('/home/stats')
        }else{
          this.$router.push('/home')
        }
      }
  }
}
</script>

<style>
.common-layout{
  padding: 0;
  margin: 0;
}
.el-header{
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  padding: 0 20px;
  text-align: left;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px);
  text-align: left;
  box-sizing: content-box;
}

.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px); 
}
.el-submenu__title,.el-menu-item{
  font-size: 12px;
}
</style>
