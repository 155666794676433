<template>
  <div class="search">
  <el-form :model="formdata"  ref="formdata"  class="demo-form-inline" label-width="120px" :rules="rules" >
        <template v-if="!istkmkedit">
          <el-form-item label="选择学段"  prop="xueduan">
          <el-select v-model="formdata.xueduan" placeholder="选择学段" @change="change($event,selectdata[0].type)"  >
            <el-option :label="sd" :value="sd" v-for="(sd,sidx) in selectdata[0].modulename.split(',')" :key="sidx"  :disabled="tkmkid != -1"></el-option>
          </el-select>
         </el-form-item> 
         <el-form-item label="选择年级" prop="grade" v-if="selectgrade" >
          <el-select v-model="formdata.grade" placeholder="选择年级" @change="change($event,selectgrade.type)"  >
            <el-option :label="sd" :value="sd" v-for="(sd,gsidx) in selectgrade.value.split(',')" :key="gsidx" :disabled="tkmkid != -1" ></el-option>
          </el-select>
         </el-form-item>   
         <el-form-item label="选择学课"  prop="xuekename"  v-if="selectkemu" >
          <el-select v-model="formdata.xuekename" placeholder="选择学课" @change="change($event,selectkemu.type)"  >
            <el-option :label="xk" :value="xk" v-for="(xk,xkidx) in selectkemu.value.split(',')" :key="xkidx" :disabled="tkmkid != -1"></el-option>
          </el-select>
         </el-form-item>    
        </template>  
        <template v-else>
          <el-form-item label="选择学段" >
            <el-input v-model="formdata.xueduan" placeholder="" :disabled="true" ></el-input>
         </el-form-item> 
         <el-form-item label="选择年级"  >
          <el-input v-model="formdata.grade" placeholder="" :disabled="true"></el-input>
         </el-form-item> 
         <el-form-item label="选择学课" >
          <el-input v-model="formdata.xuekename" placeholder="" :disabled="true"></el-input>
         </el-form-item> 
        </template>         
         <el-form-item label="标题" prop="bt" >
            <el-input v-model="formdata.bt" placeholder="请输入标题"  :disabled="istkmkedit"></el-input>
         </el-form-item>  
           <el-form-item label="学习描述" prop="xxms" > 
           <el-input type="textarea" v-model="formdata.xxms" :disabled="istkmkedit"></el-input>
          </el-form-item>     
          <el-form-item >       
      <el-button type="primary" @click="onSubmit('formdata')"  v-if="tkmkid == -1 && formdata.xuekename && !istkmkedit" >确定</el-button>
      <!-- <el-button type="primary" v-if="isxdkmkedit" @click="onSubmit('formInline')">编辑</el-button> -->
    </el-form-item>
          <template v-if="tkmkid != -1" >
           <h1>题库内容</h1>    
            <el-form-item label="添加题库内容">
              <div class="tklist" v-for="(xdkitem,xidx) in tklist" :key="xidx" >
                <div class="blue">
                  {{xdkitem.bt}}
                </div>
                <el-button type="primary"  @click="xdklEdit(xdkitem.orderid,xidx)" icon="el-icon-edit" circle></el-button>
                  <el-button type="danger" @click="xdklDelete(xdkitem.orderid,xidx)" icon="el-icon-delete" circle></el-button>
              </div>
              <el-button @click="addks('formdata')">添加</el-button>  
              </el-form-item>
               <el-form-item >
              <span class="red">保存路径系列模块后才能添加课程内容</span>
            </el-form-item>  
          </template>
  </el-form> 
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'adddata',
  props: {
    istkmkedit:{
       type:Boolean,
      default:false
    },
    selectdata: {
      type:Array,
      default:()=>[]
    },
    isxdkmk: {
      type:Boolean,
      default:false
    },
    tkmkid:{
      type:String,
      default:'-1'
    },
    tklist:{
      type:Array,
      default:()=>[]
    },
    edittkmkid:{
      type:String,
      default:"-1"
    },
    curxdkmk:{
       type:Object,
      default:()=>{}
    },
    edidtkData:{
       type:Object,
      default:()=>{}
    },
    iseditxdkkc:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    ...mapState(['skt'])
  },
  watch:{
    'edidtkData':{
      handler:function(nval){
        console.log(this.formdata)
      this.formdata = nval
      },
      immediate:true
    },
    
    filelst(newval){
     this.formdata.filelist=newval
    },
    'skt.saveMsg.2000':{
      handler:function(nval){
        let arr = nval[0].value.split(',')
        if(nval[0].type == 101){
          this.selectgrade = nval[0]
          this.$store.dispatch('saveGrade',arr)
        }else if(nval[0].type == 102){
          this.$store.dispatch('saveKemu',arr)
          this.selectkemu = nval[0]
        }else if(nval[0].type == 103){
          this.$store.dispatch('saveKaodian',arr)
          this.selectkaodian = nval[0]
        }       
      },
      deep:true
    }
    // 'curxdkmk':{
    //   handler:function(nval){
    //      this.formdata.xzmk = nval.xzmk
    //   this.formdata.xzlx = nval.xzlx
    //   },
    //   deep:true,
    //   immediate:true
    // }
  },
  data() {
      return {
      tvalue:'',
      selectkemu:undefined,
      selectkaodian:undefined,
      selectgrade:undefined,
      formdata: {
        xueduan:undefined,
        xuekename:undefined,
        grade:undefined,
        xxms:undefined,
        bt:undefined
      } ,
      dttype:["单选","多选","填空","判断","语音"],
      rules: {
        xueduan: [
            { required: true, message: '请选择学段', trigger: 'change' }
          ],
          // grade: [
          //   { required: true, message: '请选择年级', trigger: 'change' }
          // ],
          // xuekename: [
          //   { required: true, message: '请选择学课', trigger: 'change' }
          // ],
          bt: [
            { required: true, message: '请输入标题', trigger: 'blur' },
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
          ]
       },
      uploadUrl:this.$store.state.BASE_URL+'file/upload' ,
      videoForm0:{
        video:'',
        movurl:""
      },
      videoForm1:{
        video:'',
        movurl:""
      },
      curindex:-1,
      videoFlag:true,
      videoFlag1:true,
      videoUploadPercent:0,
      filelst:[],
      filelst1:[],
      filelst2:[],
      filelst3:[],
      audioList0:{
        url:''
      },
      audioList1:{
        url:''
      },
      audioList2:{
        url:''
      },
      audioDuration:0
    }
  },
  methods: {
    xdklEdit(id){
      let sdata = {
        etid:"2014",
        params:{
          orderid:id,
          kcorderid:this.tkmkid
        }
      }      
      this.$emit("oprxdkkc",sdata)
    },
    xdklDelete(id,index){
      let sdata = {
        etid:"2013",
        params:{
          orderid:this.tkmkid,
          kcorderid:id
        }
      }      
      this.$emit("oprxdkkc",sdata)
      this.tklist.splice(index,1)
    },
    addks(formName){
      this.$refs[formName].validate((valid) => {
          if (valid) {
             this.$emit("addxdkkc",1)
          } else {
            return false;
          }
        });
    },
    change(e,type){
      if(type == 100){
        this.selectgrade = undefined
        this.selectkemu = undefined
        this.selectkaodian = undefined
        // this.formdata.grade = undefined
      }else if(type == 101){
        this.selectkemu = undefined
        this.selectkaodian = undefined
        // this.formdata.xuekename = undefined
      }else if(type == 102){
        this.selectkaodian = undefined
      }
      let datas = {
        etid:"2000",
        params:{type:type,value:e}
      }
      this.$store.dispatch("sendMessage",datas)
    },
    dellvdo(num,index){
      console.log(index,num)
      switch (num) {
        case 0:
            this.formdata.videoarr0 = []
            this.videoFlag = false
          break;
         case 1:
          this.formdata.xtj[index].jxmov = []
            this.videoFlag1 = false
          break;      
        default:
          break;
      }
    },
    handleChange(e,id){
      this.formdata[id]=e
    },
    addda(index){
				this.formdata.xtj[index].wtda.push(this.tvalue)
			},
			delda(index){
				if(this.formdata.xtj[index].wtda.length>1){
					this.formdata.xtj[index].wtda.splice(this.formdata.xtj[index].wtda.length-1,1)
				}
			},
    addItem(num){
				var dataTemp = {
        wtindex:num,
        wtms:'',
        wttp:[],
        wtyy:{},
        wtda:[],
        jxwz:'',
        jxtp:[],
        wtjg:'',
        jxyy:{},
        jxspdz:'',
        jxmov:[],
      }
      this.formdata.xtj.push(dataTemp)
      this.formdata.xtj[this.formdata.xtj.length-1].wtda.push(this.tvalue)
      this.formdata.xtj[this.formdata.xtj.length-1].wtda.push(this.tvalue)
    },
    delxtj(i){
      this.formdata.xtj.splice(i,1)
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let etid = "2010"
          let sdata = {
            etid:etid,
            params:this.formdata
          }      
          this.$emit("savexdkmk",sdata)
        } else {
          return false;
        }
      });    
    },
     poptips(msg){
            this.$message({
              message:msg,
              type: 'warning'
            });
        },
    //文件图片
    handleAvatarSuccess(res,index) {
       if(res.code == 0){
         let url = res.url
            this.formdata.xtj[index].wttp = url
       }
    },
    handleAvatarSuccess1(res,index) {
       if(res.code == 0){
         let url = res.url
          this.formdata.xtj[index].jxtp = url
       }
    },
    error(){
      this.$message.error('文件上传失败!');
    },
    beforeAvatarUpload(file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //上传语音
    beforeAudioUpload(file){
      // 文件类型进行判断
        const isAudio = file.type === "audio/mp3" || file.type === "audio/mpeg";
        // 限制上传文件大小 2M
        const isLt2M = file.size / 1024 / 1024 < 2;     
       
        // 获取时长
        this.getTimes(file);
        if (!isAudio) {
            this.$message.error("上传文件只能是Mp3格式!");
        } else {
            if (!isLt2M) {
                this.$message.error("上传文件大小不能超过 2MB!");
            }
        }
        return isAudio && isLt2M
    },
    getTimes(file) {
            var content = file;
            //获取录音时长
            var url = URL.createObjectURL(content);
            //经测试，发现audio也可获取视频的时长
            var audioElement = new Audio(url);
            audioElement.addEventListener("loadedmetadata", () => {
                this.audioDuration = parseInt(audioElement.duration);
                 const isTime300S = this.audioDuration >= 300 ? true : false;  
               if (isTime300S) {
                    this.$message.error("上传文件时长不能超过5分钟!");
                 }                
            });
        },
    handleAudioSuccess(res,index){  
      if(res.code == 0){   
          // this.audioList0.url = res.url    
        this.formdata.xtj[index].wtyy["content"] = res.url
         this.formdata.xtj[index].wtyy["contentduration"] = this.audioDuration
         this.formdata.xtj[index].wtyy["anmitionplay"]=0
       }else{
        this.$message.error('语音上传失败，请重新上传！');
      }
    },
    handleAudioSuccess1(res,index){    
      if(res.code == 0){   
        this.audioList1.url = res.url        
        this.formdata.xtj[index].jxyy["content"] = res.url
        this.formdata.xtj[index].jxyy["contentduration"] = this.audioDuration
        this.formdata.xtj[index].jxyy["anmitionplay"]=0
       }else{
        this.$message.error('语音上传失败，请重新上传！');
      }
    },
     handleAudioSuccess2(res){ 
      if(res.code == 0){   
        // this.audioList2.url = res.url   
         this.formdata.audioarr2["content"] = res.url
        this.formdata.audioarr2["contentduration"] = this.audioDuration
        this.formdata.audioarr2["anmitionplay"]=0
       }else{
        this.$message.error('语音上传失败，请重新上传！');
      }
    },
    handleChangeAudio(file, fileList){
      console.log(file,fileList)
    },
    //视频上传
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024  < 10;
      if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
          this.$message.error('请上传正确的视频格式');
          return false;
      }
      if (!isLt10M) {
          this.$message.error('上传视频大小不能超过10MB哦!');
          return false;
      }
    },
    uploadVideoProcess(file){
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percent.toFixed(0));
    },
    handleVideoSuccess(res) {                   
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if(res.code == 0){
        // this.videoForm0.movurl = res.movurl
        // this.videoForm0.video = res.url;       
        this.formdata["videoarr0"].push(res.movurl)
         this.formdata["videoarr0"].push(res.url)
      }else{
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    handleVideoSuccess1(res,index) {                      
      this.videoFlag1 = false;
      this.videoUploadPercent = 0;
      if(res.code == 0){
          this.videoForm1.movurl = res.movurl
        this.videoForm1.video = res.url;
        this.formdata.xtj[index]["jxmov"].push(res.movurl)
        this.formdata.xtj[index]["jxmov"].push(res.url)
      }else{
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    //上传文件
    handleFileRemove(file, fileList) {
      console.log(file)
      this.filelst = []
      for(let i in fileList){
         this.filelst.push({name:fileList[i].name,url:fileList[i].response.url})
      }
    },
    handleAudioRemove(){
      this.formdata.audioazrr0 = {}
      this.audioList0={url:''}
    },
     handleAudioRemove1() {
      this.formdata.audioazrr1 = {}
      this.audioList1={url:''}
    },
    handleAudioRemove2(){
      this.formdata.audioazrr2 = {}
      this.audioList2={url:''}
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleFileRemove1(){
  this.formdata.jxtp = ''
    },
    handleFileRemove0(index){
      this.formdata.xtj[index].wttp = ''
    },
  
    clearfform(){
      this.videoForm0={
          video:'',
          movurl:""
        }
        this.videoForm1={
          video:'',
          movurl:""
        }
        this.videoUploadPercent=0
        this.filelst=[]
        this.audioList0={
          url:''
        }
        this.audioList1={
          url:''
        }
        this.audioList2={
          url:''
        }
        this.videoFlag = true
        this.videoFlag1 = true
        this.audioDuration=0
        this.formdata.filelist = []
        this.formdata.jxtp=''
        this.formdata.bt=''
        this.formdata.xtj=[]
        this.formdata.videoarr0=[] 
        this.formdata.videolink0=''
        this.formdata.wtindex=''
        this.formdata.audioarr2={url:''}
        this.filelst1=[]
        this.filelst2=[]
        this.filelst3=[]
    },
    dellAudio(num,index){
      switch (num) {
        case 0:
           this.formdata.xtj[index].wtyy={url:''}
           this.audioList0={
            url:''
           }
          this.filelst2 = []
          break;
        case 1:
            this.formdata.xtj[index].jxyy={url:''}
           this.audioList1={
            url:''
           }
          this.filelst3 = []
          break;
        case 2:
            this.formdata.audioarr2={url:''}
           this.audioList2={
            url:''
           }
          this.filelst1 = []
          break;        
        default:
          break;
      }
    },
     resetForm(formName) {
       this.clearfform()
         this.$refs[formName].resetFields();
    },
    handleFileSuccess(res,file){
      if(res.code == 0){
        let obj = {
          url:res.url,
          name:file.name
        }
        this.formdata.filelist.push(obj)
      }else{
          this.$message.error('文件上传失败，请重新上传！');
      }
    }
  },
  onunload(){
    this.$refs["formdata"].resetFields();
  }
}
</script>
<style>
.search{
  text-align: left;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
        
  }
  h1{
    display: flex;
    align-items:center; 
    justify-content: space-between;
  }
  h1 >div{
    margin-right: 10px;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #8c939d;
    width:100px;
    height: 60px;
    padding-top: 20px;
    text-align: center;
  }
  .tklist{
    padding-bottom: 5px;
    display: flex;
  }
  .avatar {
    width: 200px;
    height: 178px;
    display: block;
  }
  .item{
    border-radius: 8px;
    padding: 0 10px;
    margin-bottom: 10px;
    border:1px solid #409EFF;
  }
</style>
