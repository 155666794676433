import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/assets/js/websocket'

router.beforeEach((to,from,next)=>{
  // console.log("from",from)
  if(to.meta.title){
    document.title = to.meta.title
  }
  if(to.meta.requireAuth){
    if(store.state.token){
      next()
    }else{
      next({
        path: '/',
      })
    }
  }else{
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
