<template>
  <div class="common-layout">
    <div v-if="menutype == 1">  
      <div class="search-wrap">
        <div  class="topinfo-box" v-if="topinfo.length">
          <div v-for="(tinfo,tidx) in topinfo" :key="tidx">{{tinfo.info}}</div>
        </div>
        <search :searchData="searchData" @subsdata="subsdata" v-if="searchData.length"></search>
      </div>
      <div class="increased">
        <div  @click="increased(bitem.showname,bitem.type,bitem.buttoneid)" v-for="(bitem,bidx) in buttondata"  :key="bidx">
            <el-button type="primary" v-if="Number(bitem.isshow)">{{bitem.showname}}</el-button>
        </div>           
      </div>
      <div class="list-wrap">              
        <statslist :headData="headData" :resData="resData" :pageData="pageData" @editinfo="editinfo"
          @pagechange="pagechange" @download="download"
          @delinfo="delinfo"
        ></statslist>
      </div>
    </div>  
      <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="60%">
      <div class="add-wrap">
        <template v-if="addtype == 8888">
           <xdkaddcon :selectdata="selectdata"  @saveaddinfo="saveaddinfo" @clsDialog="clsDialog" :iscls="dialogVisible"></xdkaddcon>
        </template>
        <template v-else>
          <editcon :editData="editData" :oprtype="oprtype" @saveinfo="saveinfo" @clsDialog="clsDialog" :iscls="dialogVisible"></editcon>
        </template>
      </div>
    </el-dialog>
    <el-drawer
    title="详情内容"
    :visible.sync="drawer"
    :direction="direction"
    size="89%"
    :before-close="handleClose">
    <div class="search-wrap">
        <div  class="topinfo-box" v-if="stopinfo.length">
          <div v-for="(stinfo,stidx) in stopinfo" :key="stidx">{{stinfo.sinfo}}</div>
        </div>
        <search :searchData="ssearchData" @subsdata="subsdata" v-if="ssearchData.length"></search>
      </div>
      <div class="list-wrap">              
        <statslist :headData="sheadData" :resData="sresData" :pageData="spageData" @seditinfo="editinfo"
          @pagechange="pagechange" @sdownload="download"
          @delinfo="delinfo"
        ></statslist>
      </div>
  </el-drawer>
   </div>
  
</template>
<script>
import statslist from '@/components/statslist'
import search from '@/components/search'
import editcon from '@/components/editcon.vue'
import xdkaddcon from "@/components/xdkaddcon.vue"
import {mapState} from 'vuex'
export default {
  name: 'Stats',
  components:{
    statslist,
    search,
    xdkaddcon,
    editcon
  },
  computed:{
    ...mapState(['skt'])
  },
  watch:{
    'skt.saveMsg.1902':{
      handler:function(nval){
        this.menuData = nval
      }
    },    
    'skt.saveMsg.1903':{
      handler:function(nval){
        this.searchData = nval
      }
    },    
    'skt.saveMsg.1904':{
      handler:function(nval){
        this.headData = nval
      }
    },
    'skt.saveMsg.1905':{
      handler:function(nval){
        this.resData = nval
      }
    },
    'skt.saveMsg.1906':{
      handler:function(nval){
        this.pageData = nval
      }
    },
    'skt.saveMsg.1918':{
      handler:function(nval){
        this.addData = nval
      }
    },
    'skt.saveMsg.1908':{
      handler:function(nval){
        this.buttondata = nval
      }
    },
    'skt.saveMsg.6540':{
      handler:function(nval){
        this.editData = nval
      }
    },
    'skt.saveMsg.1907':{
      handler:function(nval){
        this.increaseddata = nval
      }
    },
     'skt.saveMsg.1909':{
      handler:function(nval){
        this.topinfo = nval
      }
    },
    'skt.saveMsg.1910':{
      handler:function(nval){
        this.selectdata = nval
      }
    },
    'skt.saveMsg.6669':{
      handler:function(nval){
        this.export(nval)
      }
    },
    'skt.saveMsg.1899':{
      handler:function(nval){
        this.stopinfo = nval
      }
    },
    'skt.saveMsg.1893':{
      handler:function(nval){
        this.ssearchData = nval
      }
    },
    'skt.saveMsg.1894':{
      handler:function(nval){
        this.sheadData = nval
        this.drawer = true
      }
    },
     'skt.saveMsg.1895':{
      handler:function(nval){
        this.sresData = nval
      }
    },
    'skt.saveMsg.1896':{
      handler:function(nval){
        this.spageData = nval
      }
    },
  },
  data(){
    return {
      title:"编辑",
      topinfo:[],
      increaseddata:[],
      dialogVisible: false,
      menuActive:'1-1',
      searchData:[],
      menuData:[],
      resData:[],
      headData:[],
      pageData:{},
      menutype:1,
      addData:[],
      editData:[],
      oprtype:0,
      buttondata:{},
      addtype:0,
      selectdata:[],
      cursearch:undefined,
      drawer: false,
      direction: 'rtl',
      stopinfo:[],
      ssearchData:[],
      sresData:[],
      sheadData:[],
      spageData:{},
    }
  },
  methods:{
      goto(bid,type,menuActive){
        this.menutype = type
        let datas = {
          etid:bid,
          params:{}
        }
        this.$store.dispatch("sendMessage",datas)  
        this.menuActive=menuActive
      },
      subsdata(datas){
        this.cursearch = datas.params
        this.$store.dispatch("sendMessage",datas)
      },
      addinfo(datas){        
        // this.goto("1915","1","1-3")      
        this.$store.dispatch("sendMessage",datas)
      },
      pagechange(datas){
        console.log(datas)
       let temp = {
         etid:datas.etid,
         params:{...this.cursearch,...datas.params}
       }
        this.$store.dispatch("sendMessage",temp)
      },
      saveinfo(datas){        
       this.clsDialog()
       this.$store.dispatch("sendMessage",datas)
      },
      saveaddinfo(datas){
        this.clsDialog()
       this.$store.dispatch("sendMessage",datas)
      },
      editinfo(obj){
        this.oprtype = 0
        this.title = "编辑"
        let datas = {
          etid:obj.etid,
          params:{sid:obj.sid}
        }
        this.$store.dispatch("sendMessage",datas)  
        this.dialogVisible = true
      },
      clsDialog(){
        this.dialogVisible = false
      },
      download(obj){
         let datas = {
            etid:obj.etid,
            params:{sid:obj.sid}
          }
          this.$store.dispatch("sendMessage",datas)
      },
      delinfo(obj){
         this.$confirm('你确定本操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           let datas = {
            etid:obj.etid,
            params:{sid:obj.sid}
          }
          this.$store.dispatch("sendMessage",datas)
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消本操作!'
          });          
        });
      },
      increased(title,type,eid){
        if(type=="8888"){
          this.addtype = type
        }else if(type==2){
          let datas = {
            etid:eid,
            params:{}
          }
          this.$store.dispatch("sendMessage",datas)
        }else{
          this.oprtype = 1
          this.editData = this.increaseddata
        }
        if(type != 2){
          this.title = title
         this.dialogVisible = true
        }        
      },
      //下载
      export(val) {        
          // var name = val[0].filename;
          var url = val[0].downloadurl;
          window.open(url, '_self')
          //  window.location.href = url
          // const a = document.createElement('a')
          // a.setAttribute('download', name)
          // a.setAttribute('target', '_blank')
          // a.setAttribute('href',url)
          // a.click()
      } ,
       handleClose(done) {
         console.log(done)
        this.$confirm('确认关闭详情内容吗？')
          .then(res => {
            console.log(res)
            done();
          })
          .catch(ch => {console.log(ch)});
      }   
  }
}
</script>

<style>
.common-layout{
  padding: 0;
  margin: 0;
}
.el-header{
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  padding: 0 20px;
  text-align: left;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px);
  text-align: left;
  box-sizing: content-box;
}

.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px); 
}
.el-submenu__title,.el-menu-item{
  font-size: 12px;
}
.el-dialog__wrapper{
  text-align: left;
}
.increased{
  padding-bottom: 20px;
  text-align: left;
  display: flex;
}
.topinfo-box>div{
padding-bottom: 10px;
}
</style>
