<template>
  <div class="list">
     <el-table
    :data="resData"
    stripe
    style="width: 100%">
    <el-table-column
      v-for="(hdata,hidx) in headData " :key="hidx"
      :prop="hdata.fieldname"
      :label="hdata.fieldshow">
        <template slot-scope="scope"> 
          <span v-if="hdata.type != 'img' && hdata.type != 'mp4' && hdata.type != 'addupdate'" :style="scope.row.color">
            {{scope.row[hdata.fieldname]}}
          </span>
          <span v-if="hdata.type == 'img'">
            <img :src="scope.row[hdata.fieldname]"  min-width="70" height="70" />
          </span>
          <span v-if="hdata.type == 'mp4'">
            <video width="320" height="100" controls >
              <source :src="scope.row[hdata.fieldname]" type="video/mp4" >
            </video> 
          </span>
          <span v-if="hdata.type == 'addupdate'">
            <el-button v-for="(hf,nidx) in scope.row[hdata.fieldname]" :key="nidx" :disabled="hf.enable!=0?false:true" 
            size="mini"   @click="operation(hf,scope.row.sid)">{{hf.name}}</el-button>
          </span>
        </template>  
    </el-table-column> 
  </el-table>
   <el-pagination
    layout="prev, pager, next"
    :current-page="Number(curpage)"
    :page-size="Number(pageData.pagesize)"
    :total="Number(pageData.total)"
    @current-change="handleCurrentChange"
    >
  </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    resData: {
      type:Array,
      default:()=>[]
    },
    headData: {
      type:Array,
      default:()=>[]
    },
    pageData:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      curpage:1
    }
  },
  watch:{
    pageData(newval){
      console.log("curpage",this.curpage)
      this.curpage = newval.curpage
    }
  },
  methods:{
    handleCurrentChange: function(currentPage){
      let sdata = {
        etid:this.pageData.eid,
        params:{
          lastsid:this.resData[this.resData.length-1].sid,
          curpage:currentPage
        }
      }      
      this.$emit("pagechange",sdata)
    },
    operation(item,sid){
      if(item.type == 1){
        this.handleEdit(item.etid,sid)
      }else if(item.type == 2){
        this.handleDelete(item.etid,sid)
      }else if(item.type == 3){
        this.downloadfile(item.etid,sid)
      }
    },
    downloadfile(etid,sid) {
       this.$emit("download",{etid:etid,sid:sid})
    },
    handleEdit(etid,sid) {
       this.$emit("editinfo",{etid:etid,sid:sid})
    },
    handleDelete(etid,sid) {
      this.$emit("delinfo",{etid:etid,sid:sid})
    }
  }
}
</script>

<style>
.list{
  text-align: left;
}
.clrred{
  color: red;
}
.clrblue{
  color: blue;
}
.el-table-column{
  background-color: red !important;
}
.el-pagination{
  padding: 0;
  margin:15px 0 0 0;
}
</style>
