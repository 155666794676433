import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Home from '../views/home.vue'
import Main from '../views/main'
import Add from '../views/add'
import Stats from '../views/stats'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta:{
      title:"登录"
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    meta:{
      title:"首页",
      requireAuth:true
    },
    children:[
      {
        path:'',
        component:Main,
        meta:{
          title:"列表",
          requireAuth:true
        }
      },
      {
        path:"add",
        component:Add,
        meta:{
          title:"添加",
          requireAuth:true
        }
      },
      {
        path:"stats",
        component:Stats,
        meta:{
          title:"统计",
          requireAuth:true
        }
      }      
    ]
   }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
