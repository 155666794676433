<template>
  <div class="login">
    <div class="warp">
      <div class="box">
       <img src="../assets/icon0.png" /> <input  type="text" v-model="form.username" placeholder="请输入账号" />
      </div>
       <div class="box">
     <img src="../assets/icon1.png" /> <input  type="password" v-model="form.passwd" placeholder="请输入密码" />
       </div>
        <div class="box1">
          <button @click="login" class="button">登录</button> 
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
export default {
  name: 'login',
  data(){
    return{
      form:{
        username:'',
        passwd:''
      }
    }
  },  
  computed:{
    ...mapState(["skt"])
  },
  watch:{
    'skt.saveMsg.1901':{
      handler:function(newval){
        if(newval.status == "1"){         
          this.$message({
            message:newval.msg,
            type: 'success'
          });
          this.$store.commit('savetoken',newval.uid)
          this.$router.push('./home')
        }else{
           this.$message({
            message:newval.msg,
            type: 'warning'
          });
        }    
      },
      deep:true
    }
  },
  methods:{
    login(){
      if(this.$data.form.username == '' || this.$data.form.passwd == ""){
        this.$message({
          message: '请输入账号或者密码',
          type: 'warning'
        });
      }else{
        let datas = {
        etid:"1901",
        params:this.$data.form
      }
      if(this.skt.socket.isConnected){
        this.$store.dispatch("sendMessage",datas)
      }else{
        this.$message.error('服务器还没连接成功...');
      }
      }
    }
  }
}
</script>
<style >
.login{
  height: 100vh;
  width: 100vw;
  background: url("../assets/bg.jpg") no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
}
.warp{
  background: url("../assets/box.png") no-repeat center center;
  width: 500px;
  height: 260px;
  background-size: 100% 100%;
  padding-top:100px;
  margin: 0 auto;
  position:relative;
  top:-40px;
}
.box{
  background: url("../assets/input.png") no-repeat center center;
  width: 320px;
  background-size: 100% 100%;
  height:46px;
  margin:0 auto 20px auto;  
  display: flex;
  align-items: center;
  justify-content: center;
}
.box img{
  width: 25px;
  height: auto;
}
.box input{ 
  width: 70%;
  height: 60%;
  margin: 0 0 0 5px;
  padding: 0 10px;
   border: 0px;
   background-color: none;
   outline: none;
   color: #5cb7fe;
   background: transparent;
}
input:-internal-autofill-selected {
  background: transparent !important;
}
.button{  
  margin:0 auto 20px auto;  
  border:none;
  background: url("../assets/b1.png") no-repeat center center;
  width: 320px;
  background-size: 100% 100%;
  color: #fff;
  height:40px;
  cursor: pointer;
}
:-moz-placeholder{color:#5cb7fe;}
::-moz-placeholder{color:#5cb7fe;}
input:-ms-input-placeholder,textarea:-ms-input-placeholder{color:#5cb7fe;}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{color:#5cb7fe;}
*{-webkit-tap-highlight-color:rgba(255,0,0,0);}
input:-webkit-autofill{  
    background-image: url("../assets/icon1.png") no-repeat left center !important;
    -webkit-text-fill-color: #5cb7fe !important;  
    transition: background-color 50000s ease-in-out 0s;  
  }
</style>
