<template>
  <div class="search">
  <el-form :inline="true" :model="formInline" ref="formInline" class="demo-form-inline">
    <el-form-item :label="sd.showname"  v-for="(sd,index) in searchData" :key="index" :class="{'info':sd.type == 5}">        
        <el-input v-model="formInline[sd.queryid]" :placeholder="sd.showname" v-if="sd.type == 1"></el-input>
        <el-select v-model="formInline[sd.queryid]" :placeholder="sd.showname" v-if="sd.type == 2">
          <el-option :label="rlc" :value="rlc" v-for="(rlc,ridx) in sd.inputinfo.split(',')" :key="ridx"  ></el-option>
        </el-select>
        <el-checkbox-group v-model="sd.empty"  v-else-if="sd.type == 4"  @change="handleChange($event,sd.queryid)">
          <el-checkbox :label="slc" name="type" v-for="(slc,sidx) in sd.inputinfo.split(',')" :key="sidx"></el-checkbox>         
        </el-checkbox-group>
    </el-form-item>   
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>  
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    searchData: {
      type:Array,
      default:()=>[]
    }
  },
  data() {
      return {
      formInline: {}    
    }
  },
  watch:{
    searchData(newval){
      if(newval){
        this.formInline = {} 
      }    } 
  },
  methods: {
    handleChange(e,id){      
      this.formInline[id]=e
    },
    onSubmit(){
      let sdata = {
        etid:this.searchData[0].eid,
        params:this.formInline
      }   
      this.$emit("subsdata",sdata)
      // this.$refs["formInline"].resetFields();
      // this.formInline = {} 
    }
  }
}
</script>
<style>
.search{
  text-align: left;
}
</style>
