<template>
  <div class="xdkmk">
  <el-form :model="formInline"  ref="formInline"  class="demo-form-inline" label-width="120px" :rules="rules" >
      <el-form-item label="选择模块"   prop="xzmk">
          <el-select v-model="formInline.xzmk" placeholder="选择模块"  >
            <el-option :label="sd" :value="sd" v-for="(sd,sidx) in selectdata[0].modulename.split(',')" :key="sidx"  :disabled="orderid != -1"></el-option>
          </el-select>
         </el-form-item>  
         <el-form-item label="标题" prop="bt" >
            <el-input v-model="formInline.bt" placeholder="请输入标题" ></el-input>
         </el-form-item>    
          <el-form-item label="选择类型" prop="xzlx" >
          <el-select v-model="formInline.xzlx"  >
            <el-option :label="td" :value="tidx" v-for="(td,tidx) in typedata" :key="tidx" :disabled="orderid != -1" ></el-option>
          </el-select>
         </el-form-item>  
          <el-form-item label="学习难度" prop="xxnd" > 
            <el-rate v-model="formInline.xxnd"></el-rate>
          </el-form-item>     
           <el-form-item label="学习描述" prop="xxms" > 
           <el-input type="textarea" v-model="formInline.xxms"></el-input>
          </el-form-item>    
          <el-form-item > 
            <el-button type="primary" v-if="orderid == -1" @click="onSubmit('formInline')">保存</el-button>
            <el-button type="primary" v-if="isxdkmkedit" @click="onSubmit('formInline')">编辑</el-button>
          </el-form-item>
          <template v-if="orderid != -1" >
           <h1>课程内容</h1>    
            <el-form-item label="添加课程内容">
              <div class="xdklist" v-for="(xdkitem,xidx) in xdkkclist" :key="xidx" >
                <div class="blue">
                  {{xdkitem.bt}}
                </div>
                <el-button type="primary"  @click="xdklEdit(xdkitem.orderid,xidx)" icon="el-icon-edit" circle></el-button>
                  <el-button type="danger" @click="xdklDelete(xdkitem.orderid,xidx)" icon="el-icon-delete" circle></el-button>
              </div>
              <el-button @click="addks('formInline')">添加</el-button>  
              </el-form-item>
               <el-form-item >
              <span class="red">保存路径系列模块后才能添加课程内容</span>
            </el-form-item>  
          </template>
  </el-form>  
  </div>
</template>

<script>
export default {
  name: 'adddata',
  props: {
    orderid:{
       type:String,
      default:"-1"
    },
     isxdkmkedit:{
       type:Boolean,
      default:false
    },
    edidmkData:{
      type:Object,
      default:()=>{}
    },
    selectdata: {
      type:Array,
      default:()=>[]
    },
    xdkkclist:{
      type:Array,
      default:()=>[]
    }
  },
  watch:{
      'edidmkData':{
      handler:function(nval){
      this.formInline = nval
      this.formInline.xxnd = Number(nval.xxnd)
      },
      immediate:true
    },
    orderid(nval){
      if(nval == "-1"){
        // this.$refs["formInline"].resetFields();
        }
    }
  },
  data() {
      return {     
      formInline: {
        xzmk:undefined,
        bt:undefined,
        xzlx:undefined,
         xxnd: 0,
         xxms:undefined,                  
      } ,      
      // xxnd:0,
      rules: {
         xzmk: [
            { required: true, message: '请选择模块', trigger: 'change' }
          ],
          bt: [
            { required: true, message: '请输入标题', trigger: 'blur' },
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
          ],
         xzlx: [
            { required: true, message: '请选择类型', trigger: 'change' }
          ]
       },
      typedata:["赏析","刷题","学习"]
    }
  },
  methods: {
    xdklEdit(id){
      let sdata = {
        etid:"8896",
        params:{
          orderid:this.orderid,
          kcorderid:id
        }
      }      
      this.$emit("oprxdkkc",sdata)
    },
    xdklDelete(id,index){
      let sdata = {
        etid:"8895",
        params:{
          orderid:this.orderid,
          kcorderid:id
        }
      }      
      this.$emit("oprxdkkc",sdata)
      this.xdkkclist.splice(index,1)
    },
    addks(formName){
      this.$refs[formName].validate((valid) => {
          if (valid) {
             this.$emit("addxdkkc",0)
          } else {
            return false;
          }
        });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let etid = this.isxdkmkedit?"8900":"8892"
          let sdata = {
            etid:etid,
            params:this.formInline
          }      
          this.$emit("savexdkmk",sdata)
        } else {
          return false;
        }
      });      
    }
  },
  onunload(){
    this.$refs["formInline"].resetFields();
  }
}
</script>
<style>
.red{
  color: red;
}
.xdkmk{
  text-align: left;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
        
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #8c939d;
    width:100px;
    height: 60px;
    padding-top: 20px;
    text-align: center;
  }
  .avatar {
    width: 200px;
    height: 178px;
    display: block;
  }
  .xdklist{
    padding-bottom: 5px;
    display: flex;
  }
  .blue{
    color: #409EFF;
    font-weight: bold;
    margin-right: 20px;
  }
</style>
