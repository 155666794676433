<template>
  <div class="common-layout">
    <div v-if="menutype == 1">  
      <div class="search-wrap">
        <div  class="topinfo-box" v-if="topinfo.length">
          <div v-for="(tinfo,tidx) in topinfo" :key="tidx">{{tinfo.info}}</div>
        </div>
        <search :searchData="searchData" @subsdata="subsdata" v-if="searchData.length"></search>
      </div>
      <div class="increased">
        <div  @click="increased(bitem.showname,bitem.type)" v-for="(bitem,bidx) in buttondata"  :key="bidx">
            <el-button type="primary" v-if="Number(bitem.isshow)">{{bitem.showname}}</el-button>
        </div>           
      </div>
      <div class="list-wrap">              
        <list :headData="headData" :resData="resData" :pageData="pageData" @editinfo="editinfo"
          @pagechange="pagechange"
          @delinfo="delinfo"
        ></list>
      </div>
    </div>  
      <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @closed="dialogclsd"
      width="60%">
      <div class="add-wrap">
        <template v-if="addtype == 8888">
           <xdkaddcon :selectdata="selectdata"  @saveaddinfo="saveaddinfo"  :iscls="dialogVisible"></xdkaddcon>
        </template>
        <template v-else-if ="addtype == 8890 || isxdkmkedit ">
           <xdkmk :selectdata="selectdata" 
           :isxdkmkedit="isxdkmkedit" 
           :orderid="xdkmkorderid" 
           :xdkkclist="xdkkclist" 
           @savexdkmk="savexdkmk"   
           :edidmkData="edidmkData"
           @addxdkkc="addxdkkc" 
           @oprxdkkc="oprxdkkc"
           :iscls="dialogVisible"></xdkmk>
        </template>
        <template v-else-if="addtype == 7777 || istkmkedit ">
           <tkmk :selectdata="selectdata" 
            @savexdkmk="savexdkmk"  
            :istkmkedit="istkmkedit"
            :tklist="tklist" 
            :tkmkid="tkmkid"
            :edidtkData="edidtkData"
            @addxdkkc="addxdkkc" 
            @oprxdkkc="oprxdkkc"
            :iscls="dialogVisible"></tkmk>
        </template>
        <template v-else>
          <editcon :editData="editData" @clsDialog="clsDialog" :editvalue="editvalue" :oprtype="oprtype" @saveinfo="saveinfo" :iscls="dialogVisible"></editcon>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="isxdkmk"
      width="60%">
      <div class="add-wrap">
        <template v-if="istk">     
         <tkaddcon :selectdata="selectdata" 
          :edittkdata="edittkdata"
          :tkmkid="tkmkid" 
          :edidtkData="edidtkData"
          :isedittkkc="isedittkkc"
          :isxdkmk="isxdkmk" :curtkmk="curtkmk"
          :edittkmkid="edittkmkid"
          @saveaddinfo1="saveaddinfo1" :iscls="istkmk"
          ></tkaddcon>
         </template>
         <template v-else>
          <xdkaddcon :selectdata="selectdata" 
        :editxdkdata="editxdkdata"
        :orderid="xdkmkorderid" 
        :iseditxdkkc="iseditxdkkc"
        :isxdkmk="isxdkmk" :curxdkmk="curxdkmk"
        :editorderid="editorderid"
         @saveaddinfo="saveaddinfo" :iscls="isxdkmk"
         ></xdkaddcon>
         </template>
      </div>
    </el-dialog>
   </div>
  
</template>
<script>
import list from '@/components/list'
import search from '@/components/search'
import editcon from '@/components/editcon.vue'
import xdkaddcon from "@/components/xdkaddcon.vue"
import tkaddcon from "@/components/tkaddcon.vue"
import xdkmk from "@/components/xdkmk.vue"
import {mapState} from 'vuex'
import tkmk from '../components/tkmk.vue'
export default {
  name: 'Main',
  components:{
    list,
    search,
    xdkaddcon,
    editcon,
    tkaddcon,
    xdkmk,
    tkmk
  },
  computed:{
    ...mapState(['skt'])
  },
  watch:{
    'skt.saveMsg.1903':{
      handler:function(nval){
        this.searchData = nval
      }
    },    
    'skt.saveMsg.1904':{
      handler:function(nval){
        this.headData = nval
      }
    },
    'skt.saveMsg.1905':{
      handler:function(nval){
        this.resData = nval
      }
    },
    'skt.saveMsg.1906':{
      handler:function(nval){
        this.pageData = nval
      }
    },
    'skt.saveMsg.1918':{
      handler:function(nval){
        this.addData = nval
      }
    },
    'skt.saveMsg.1908':{
      handler:function(nval){
        this.buttondata = nval
      }
    },
    'skt.saveMsg.6540':{
      handler:function(nval){
        this.editData = nval
      }
    },
    'skt.saveMsg.1907':{
      handler:function(nval){
        this.increaseddata = nval
      }
    },
     'skt.saveMsg.1909':{
      handler:function(nval){
        this.topinfo = nval
      }
    },   
     'skt.saveMsg.1910':{
      handler:function(nval){
        this.selectdata = nval
      }
    },
       'skt.saveMsg.1911':{
      handler:function(nval){
        this.editvalue = nval
          this.dialogVisible = true
             this.editData = this.increaseddata  
      }
    },
     'skt.saveMsg.8891':{
      handler:function(nval){
        this.xdkkclist = nval 
      }
    },
    'skt.saveMsg.8899':{
      handler:function(nval){
        this.xdkkclist = nval
      }
    },
     'skt.saveMsg.8892':{
      handler:function(nval){
        this.istk = false
        this.xdkmkorderid = nval[0].orderid
      }
    },
    'skt.saveMsg.2010':{
      handler:function(nval){
        this.istk = true
        this.tkmkid = nval[0].orderid
      }
    },
    'skt.saveMsg.8895':{
      handler:function(){
          this.iseditxdkkc = false
      }
    },
    'skt.saveMsg.8896':{
      handler:function(nval){
        this.isxdkmk = true
        this.iseditxdkkc = true
        this.editxdkdata = nval[0]
      }
    },
    'skt.saveMsg.2014':{
      handler:function(nval){
        this.istk = true
        this.isxdkmk = true
        this.isedittkkc = true
        this.edittkdata = nval[0]
        this.tkmkid = nval[0].orderid
        this.edittkmkid = nval[0].kcorderid
      }
    },
    'skt.saveMsg.2012':{
      handler:function(nval){
        this.tklist = nval
      }
    },
    'skt.saveMsg.2016':{
      handler:function(nval){
        this.tklist = nval
      }
    },
     'skt.saveMsg.8898':{
      handler:function(nval){
        this.addtype = 8089
        this.edidmkData = nval[0]  
        this.isxdkmkedit = true
        this.xdkmkorderid = nval[0].orderid
        this.curxdkmk = nval[0]
        this.xdkkclist = nval[0].xdkkclist 
         this.dialogVisible = true  
      }
    },
    'skt.saveMsg.10211':{
      handler:function(nval){
        this.addtype = 7777
        this.edidtkData = nval[0]  
        this.istkmkedit = true        
        this.tkmkid = nval[0].orderid
        this.curtkmk = nval[0]
        this.tklist = nval[0].tklist 
         this.dialogVisible = true  
      }
    },
  },
  data(){
    return {
      istk:false,
      title:"编辑",
      topinfo:[],
      editvalue:[],
      iseditxdkkc:false,
      isedittkkc:false,
      increaseddata:[],
      dialogVisible: false,
      menuActive:'1-1',
      searchData:[],
      editdata:[],
      xdkmkorderid:'-1',
      tkmkid:'-1',
      resData:[],
      headData:[],
      tklist:[],
      pageData:{},
      menutype:1,
      addData:[],
      editData:[],
      oprtype:0,
      buttondata:{},
      addtype:0,
      selectdata:[],
       cursearch:undefined,
       isxdkmk:false,
       curxdkmk:{},
       curtkmk:{},
       xdkkclist:[],
       editxdkdata:{},
       editorderid:"-1",
       edittkmkid:"-1",
       edittkdata:{},
       edidmkData:{},
       edidtkData:{},
      isxdkmkedit:false,
      istkmkedit:false,
      istkmk:false
    }

  },
  methods:{
    dialogclsd(){
      if(this.xdkmkorderid != '-1'){
        this.xdkmkorderid = '-1'
        this.xdkkclist = []
        this.isxdkmkedit =false
        this.edidmkData = {}
      }
      if(this.tkmkid != '-1'){
        this.tkmkid = '-1'
        this.istkmkedit =false
        this.tklist = []
        this.istk =false
        this.edittkdata = {}
      }
      
    },
    addxdkkc(num){
      if(num == 0){
        this.title = "新增课程内容"
       this.iseditxdkkc = false
        this.editorderid = "-1"
        this.editxdkdata = {}
        this.istk = false
     
      }else{
        this.title = "新增考题内容"
       this.isedittkkc = false
        this.edittkmkid = "-1"
        this.edittkdata = {}
        this.istk = true
      }
      this.isxdkmk = true
      // this.isxdkmkedit = false 
    },
    // addxdkkc1(){
    //    this.title = "新增题库内容"
    //    this.isedittk = false
    //   this.edittkmkid = "-1"
    //     this.edittkdata = {}
    //   this.istkmk = true
    //   // this.isxdkmkedit = false 
    // },
     oprxdkkc(datas){  
       if(datas.etid == "8896"){
          this.title = "编辑课程内容"
           this.iseditxdkkc = true
       }else if( datas.etid == "2014"){
          this.title = "编辑考题内容"
          this.isedittkkc = true
       }
       this.editorderid = datas.params.kcorderid
       this.edittkmkid = datas.params.kcorderid
        this.$store.dispatch("sendMessage",datas)
    },
   
      goto(bid,type,menuActive){
        this.menutype = type
        let datas = {
          etid:bid,
          params:{}
        }
        this.$store.dispatch("sendMessage",datas)  
        this.menuActive=menuActive
      },
      subsdata(datas){
        this.cursearch = datas.params
        this.$store.dispatch("sendMessage",datas)
      },
      addinfo(datas){        
        // this.goto("1915","1","1-3")      
        this.$store.dispatch("sendMessage",datas)
      },
      pagechange(datas){
       let temp = {
         etid:datas.etid,
         params:{...this.cursearch,...datas.params}
       }
        this.$store.dispatch("sendMessage",temp)
      },
      saveinfo(datas){        
       this.clsDialog()
       this.$store.dispatch("sendMessage",datas)
      },
      savexdkmk(datas){
        if(datas.etid == '2010'){
          this.curtkmk = datas.params
        }else{
          this.curxdkmk = datas.params
        }       
       this.$store.dispatch("sendMessage",datas)
      },
     saveaddinfo(datas){
       if(this.orderid=='-1'){
          this.clsDialog()
       }else if(datas.etid == '8888' ){
         this.dialogVisible = false
       }
       else{
         this.clsDialog0()
       }       
       this.$store.dispatch("sendMessage",datas)
      },
      saveaddinfo1(datas){
       if(this.tkmkid=='-1'){
          this.clsDialog()
       }else if(datas.etid == '8888' ){
         this.dialogVisible = false
       }
       else{
         this.clsDialog0()
       }       
       this.$store.dispatch("sendMessage",datas)
      },
      clsDialog0(){
        this.isxdkmk = false
      },
      editinfo(obj){
        let datas = {
          etid:obj.etid,
          params:{sid:obj.sid}
        }
        this.$store.dispatch("sendMessage",datas)  
        if(obj.etid == "8898"){
           this.title ="编辑学习路径系列模块"
          this.isxdkmkedit = true
          }
          else if(obj.etid == "10211"){
            this.title ="编辑题库模块"
            this.istkmkedit = true
         } 
        else{
         this.oprtype = 0
           this.title = "编辑"
           
        }        
      },
      clsDialog(){
        this.oprtype = 0
        this.dialogVisible = false
      },
    
      delinfo(obj){
         this.$confirm('你确定本操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           let datas = {
            etid:obj.etid,
            params:{sid:obj.sid}
          }
          this.$store.dispatch("sendMessage",datas)
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消本操作!'
          });          
        });
      },
      increased(title,type){ 
        if(type=="8888" || type=="8890" || type=="7777"){
          this.addtype = type
        }else{
          this.addtype = 0
          this.oprtype = 1
          this.editData = this.increaseddata    
        }
        this.title = title
        this.dialogVisible = true
      }
  }
}
</script>

<style>
.common-layout{
  padding: 0;
  margin: 0;
}
.el-header{
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  padding: 0 20px;
  text-align: left;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px);
  text-align: left;
  box-sizing: content-box;
}

.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  height: calc(100vh - 60px); 
}
.el-submenu__title,.el-menu-item{
  font-size: 12px;
}
.el-dialog__wrapper{
  text-align: left;
}
.increased{
  padding-bottom: 20px;
  text-align: left;
  display: flex;
}
.increased div{
  margin-right: 10px;
}
.topinfo-box>div{
padding-bottom: 10px;
}
</style>
